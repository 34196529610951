import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

// Styles
import textStyles, { fontWeights } from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "../components/ContentWrapper"
import CustomPrismicTitle from "../components/atoms/CustomPrismicTitle"
import Button from "../components/atoms/Button"
import CustomRichText from "../components/atoms/CustomRichText"
import TitleDescriptionImage from "../components/slices/TitleDescriptionImage"
import QuoteSlice from "../components/slices/QuoteSlice"
import TextDescriptionColumns from "../components/slices/TextDescriptionColumns"
import StackColumnsContent from "../components/slices/StackColumnsContent"
import RequestDemo from "../components/RequestDemo"
import CTASlice from "../components/slices/CTASlice"

// Types
import { IDemoPage } from "../prismic-types/request-demo-page"

const ResourceTemplate = ({ data, pageContext }: any) => {
  const [requestedResource, setRequestedResource] = useState<string>()
  const [, setAllowDownload] = useState(false)

  const raw = data.prismicResourceTemplate.data
  const { demoPage: demoPageData } = pageContext

  const hero = {
    category: raw.category.document.data.label,
    heading: raw.heading,
    description: raw.description,
    image: raw.image,
    downloadLink: raw.download_link,
  }
  const demoPage: IDemoPage = {
    text: demoPageData.text.html,
    title: demoPageData.title.text,
    downloadText: demoPageData.download_text.html,
    downloadTitle: demoPageData.download_title.text,
    labels: {
      name: demoPageData.name,
      company: demoPageData.company,
      email: demoPageData.email,
      notes: demoPageData.notes,
      phone: demoPageData.phone,
      interest: demoPageData.interest,
      employees: demoPageData.employees,
    },
  }

  const ctaSlices = raw.body.filter(
    (slice: any) =>
      slice.slice_type === "team_member_v2" ||
      slice.slice_type === "services_cards" ||
      slice.slice_type === "resources_cards" ||
      slice.slice_type === "deep_dive_cards"
  )

  return (
    <>
      <StyledContentWrapper>
        <MainContentWrapper>
          <ContentSection>
            {hero.category && <Subtitle>{hero.category}</Subtitle>}
            {hero.heading && (
              <CustomPrismicTitle
                data={hero.heading.raw}
                color={colors.purpleLight}
              />
            )}
            {hero.description && (
              <CustomRichText data={hero.description.html} />
            )}
            <div>
              <StyledButton
                label="Download"
                variant="download"
                onClick={() => setRequestedResource(hero.heading.text)}
                fullWidth={false}
              />
            </div>
          </ContentSection>
          {hero?.image?.fluid && (
            <ImageWrapper>
              <GatsbyImage fluid={hero.image.fluid} alt={hero.image.alt} />
            </ImageWrapper>
          )}
        </MainContentWrapper>

        {raw?.body && (
          <Container>
            <ContentWrapperStyled>
              <InnerWrapper>
                <InnerContentWidth>
                  {raw?.body?.map((slice: any, index: number) => (
                    <Fragment key={`${slice.slice_type}--${index}`}>
                      {slice.slice_type === "titledescriptionimage" && (
                        <TitleDescriptionImage
                          key={`${slice.slice_type}--${index}`}
                          primary={{
                            align: slice.primary.align,
                            description1: slice.primary.TBTDIDescription.html,
                            title: slice.primary.title.raw,
                            subtitle: slice.primary.subtitle,
                            image_position: slice.primary.image_position,
                            image: slice.primary.image,
                          }}
                        />
                      )}
                      {slice.slice_type === "quote" && (
                        <QuoteSlice
                          key={`${slice.slice_type}--${index}`}
                          primary={{
                            title: slice.primary.title.raw,
                            subtitle: slice.primary.subtitle,
                          }}
                          color="#5C47AE"
                        />
                      )}
                      {slice.slice_type === "textdescriptioncolumns" && (
                        <TextDescriptionColumns
                          key={`${slice.slice_type}--${index}`}
                          primary={{
                            columnsPerRow: slice.primary.columns_per_row,
                          }}
                          items={slice.items.map((item: any) => ({
                            title: item.title.raw,
                            description1: item.description1.html,
                          }))}
                        />
                      )}
                      {slice.slice_type === "stackcolumnscontent" && (
                        <StackColumnsContent
                          key={`${slice.slice_type}--${index}`}
                          items={slice.items.map((item: any) => ({
                            image: item.image,
                            title: item.title.raw,
                            description1: item.description1.html,
                          }))}
                        />
                      )}
                    </Fragment>
                  ))}
                </InnerContentWidth>
              </InnerWrapper>
              <BottomSection>
                {ctaSlices.map((slice: any) => (
                  <CTASlice slice={slice} />
                ))}
              </BottomSection>
            </ContentWrapperStyled>
          </Container>
        )}
      </StyledContentWrapper>
      {requestedResource && (
        <RequestDemo
          title={demoPage.downloadTitle}
          text={demoPage.downloadText}
          variant="download"
          labels={demoPage.labels}
          download={hero.heading.text}
          closeModal={() => setRequestedResource(undefined)}
          onSuccess={() => setAllowDownload(true)}
        />
      )}
    </>
  )
}

export const query = graphql`
  query resourceTemplate($uid: String!, $locale: String!) {
    prismicResourceTemplate(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        category {
          document {
            ... on PrismicResourceCategory {
              id
              uid
              data {
                label
              }
            }
          }
        }
        heading {
          html
          text
          raw
        }
        description {
          html
          text
          raw
        }
        image {
          alt
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
        }
        download_link
        body {
          ... on PrismicResourceTemplateBodyTitledescriptionimage {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
              align
              TBTDIDescription: description1 {
                html
              }
              image_position
              image {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
                alt
              }
            }
          }
          ... on PrismicResourceTemplateBodyStackcolumnscontent {
            id
            slice_type
            items {
              description1 {
                html
              }
              title {
                raw
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  sizes
                }
              }
            }
          }
          ... on PrismicResourceTemplateBodyTextdescriptioncolumns {
            id
            slice_type
            primary {
              columns_per_row
            }
            items {
              description1 {
                html
              }
              title {
                raw
              }
            }
          }
          ... on PrismicResourceTemplateBodyQuote {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
            }
          }
          ... on PrismicResourceTemplateBodyTeamMemberV2 {
            id
            slice_type
            primary {
              description1
              heading1
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    data {
                      name
                      role
                      photo {
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicResourceTemplateBodyResourcesCards {
            slice_type
            primary {
              resources_cards_button_link
              resources_cards_heading {
                raw
              }
              resources_cards_label
            }
            items {
              resources_cards_template {
                document {
                  ... on PrismicResourceTemplate {
                    uid
                    data {
                      heading {
                        text
                      }
                      description {
                        text
                      }
                      image {
                        alt
                        url
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicResourceTemplateBodyServicesCards {
            id
            slice_type
            primary {
              services_cards_button_label
              services_cards_button_link
              services_cards_heading {
                raw
              }
            }
            items {
              services_cards_template {
                document {
                  ... on PrismicServicesTemplate {
                    id
                    uid
                    data {
                      title
                      service_type
                      service_level
                      service_type_color
                      heading
                      description
                      thumbnail_image {
                        alt
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicResourceTemplateBodyDeepDiveCards {
            id
            slice_type
            primary {
              button_label
              button_link
              deep_dive_description
              deep_dive_heading {
                raw
                text
              }
            }
            items {
              deep_dive_template {
                id
                type
                uid
                document {
                  ... on PrismicDeepDiveTemplate {
                    id
                    data {
                      heading {
                        text
                        raw
                      }
                      description
                      heading_color
                      foreground_color
                      background_color
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicRequestADemoPage(lang: { eq: $locale }) {
      data {
        text {
          html
        }
        title {
          text
        }
        download_text {
          html
        }
        download_title {
          text
        }
        name
        company
        email
        notes
        phone
        interest
        employees
      }
    }
  }
`
export default ResourceTemplate

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: 89px;
  padding: 96px 16px 0px;

  ${mq.from.S`
    padding: 104px 48px 0px;
  `}

  ${mq.from.L`
    padding: 141px 88px 0px;
  `}
`

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 39px;
  align-items: flex-start;

  ${mq.from.M`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Subtitle = styled.h4`
  ${textStyles.subtitle}
  text-transform: uppercase;
  color: ${colors.main["100"]};
  font-weight: 500;
  letter-spacing: 2px;

  ${mq.from.M`
    font-size: 16px;
  `}
`
const StyledButton = styled(Button)`
  font-weight: ${fontWeights.medium};
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;

  img {
    margin-bottom: 0;
  }

  ${mq.from.M`
    max-width: 40%;
    margin: 0;
  `}
`

const Container = styled.section`
  position: relative;
  padding: 0px 0 80px;
  color: ${colors.text};

  ${mq.from.L`
    padding: 0px 0 80px;
  `}
`

const ContentWrapperStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 80px;
`

const InnerWrapper = styled.div`
  max-width: 1062px;

  ${mq.from.S`
    margin: 0 auto;
  `}
`

const InnerContentWidth = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
  max-width: 1062px;
  margin: 0 auto;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 89px;
`
